<template>
  <div class="flex flex-col-reverse space-y-4 space-y-reverse lg:flex-row-reverse lg:space-x-8 lg:space-y-0 lg:space-x-reverse">
    <div class="lg:w-1/3">
      <slot name="one-third" />
    </div>

    <div class="lg:w-2/3">
      <slot name="two-third" />
    </div>
  </div>
</template>
